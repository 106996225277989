import * as Sentry from "@sentry/react";
import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Route, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./AppContext";
import LoadingScreen from "./components/loader/LoadingScreen";
import MobileBottomNav from "./components/navBottom";
import { PrivateRoute, PublicRoute } from "./components/routeComponents";
import { history } from "./helpers/history";
import { routes } from "./routes";
import { RootState } from "./store";
import ErrorPage from "./views/404";

Sentry.init({
	dsn: "https://e96ca2e75f7d69e401c52df61d96efc1@o392754.ingest.sentry.io/4506511098970112",
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
});

const App: React.FC = () => {
	const { i18n } = useTranslation();
	const { loading, language } = useSelector((state: RootState) => state.store);
	const { userProfile } = useSelector((state: any) => state.userProfile);

	useEffect(() => {
		if (i18n.language !== language) i18n.changeLanguage(language);
	}, [language]);

	const menu = routes.map((route, index) => {
		if (route.access === false) {
			route.access = userProfile !== null;
		}

		return route.component && route.isPublic === true ? (
			<PublicRoute
				key={index}
				path={route.path}
				exact={route.exact}
				component={route.component}
			/>
		) : (
			<PrivateRoute
				key={index}
				path={route.path}
				exact={route.exact}
				component={route.component}
			/>
		);
	});

	const currentPath = window.location.pathname;
	const isPathValid = routes.some(route => route.path === currentPath);

	return (
		<div className="wraper">
			<Router history={history}>
				<AppProvider>
					<Suspense fallback={<LoadingScreen loading={loading} />}>
						{loading && <LoadingScreen loading={loading} />}
						{isPathValid ? menu : <Route path="*" component={ErrorPage} />}
					</Suspense>
					<ToastContainer autoClose={3000} />
					{userProfile && <MobileBottomNav />}
				</AppProvider>
			</Router>
		</div>
	);
};

export default App;
