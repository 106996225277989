import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Col, Dropdown, DropdownButton, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../AppContext";
import { logoutAction } from "../../actions/auth";
import { changeLanguage } from "../../actions/store";
import { languages } from "../../constants/language";
import { isNullOrUndefined } from "../../utils";
import Login from "../../views/Public/login";
import config from "../../config";
import { isDashboardPage } from "../routeComponents";

const Header: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { setModalState } = useContext<any>(AppContext);
	const { userProfile } = useSelector((state: any) => state.userProfile);
	const ossLink = config.ossUrl + "Portal/";

	useEffect(() => {
		const header = document.getElementById("myHeader");
		const sticky = header?.offsetTop || 0;

		const scrollCallBack = () => {
			if (window.pageYOffset > sticky) {
				header?.classList.add("sticky");
			} else {
				header?.classList.remove("sticky");
			}
		};

		window.addEventListener("scroll", scrollCallBack);

		return () => {
			window.removeEventListener("scroll", scrollCallBack);
		};
	}, []);

	useEffect(() => {
		const header = document.getElementById("myHeader");
		const sticky = header?.offsetTop || 0;

		if (!isDashboardPage()) {
			header?.classList.add("bg-purple");
		}

		const scrollCallBackBg = () => {
			if(isDashboardPage()){
				if (window.pageYOffset > sticky) {
					header?.classList.add("bg-purple");
				} else {
					header?.classList.remove("bg-purple");
				}
			}
		};

		window.addEventListener("scroll", scrollCallBackBg);

		return () => {
			window.removeEventListener("scroll", scrollCallBackBg);
		};
	}, []);

	return (
		<header id="myHeader">
			<Row className="headerRow">
				<Col xl={1} lg={2} xs={4} md={2}>
					<a href="/dashboard">
						<img src="./logo.png" className="img-fluid custom-max-height" alt="#" />
					</a>
				</Col>

				<Col md="auto" xs={7}>
					<div className="header-wrapper">
						{!isNullOrUndefined(userProfile) && (
							<DropdownButton
								title={
									<>
										<Image
											src={
												!isNullOrUndefined(userProfile.user_profile_picture)
													? userProfile.user_profile_picture
													: ossLink + "account.png"
											}
											alt=""
											width={28}
											height={26}
											roundedCircle
											className="object-fit-cover"
										/>
										{!isNullOrUndefined(userProfile.user_name) &&
											userProfile.user_name}{" "}
										<FontAwesomeIcon icon={faAngleDown} />
									</>
								}
								id="dropdown-menu-user"
							>
								<Dropdown.Item eventKey="1" href="/profile">
									{t("My Profile")}
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item
									eventKey="2"
									onClick={async () => {
										dispatch(logoutAction(userProfile.user_id));
									}}
								>
									{t("Logout")}
								</Dropdown.Item>
							</DropdownButton>
						)}
						<DropdownButton
							title={<i className="icon_globe-2" aria-hidden="true"></i>}
							id="dropdown-menu"
						>
							{languages.map((lang, index) => {
								return (
									<>
										<Dropdown.Item
											key={index}
											eventKey={index}
											onClick={() => {
												dispatch(changeLanguage(lang.value));
												localStorage.setItem("Language", lang.value);
											}}
										>
											<img src={lang.img} alt="icon" />
											{lang.label}
										</Dropdown.Item>
										{index !== languages.length - 1 && <Dropdown.Divider />}
									</>
								);
							})}
						</DropdownButton>
					</div>
				</Col>
			</Row>
			<Login />
		</header>
	);
};
export default Header;
