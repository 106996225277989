import React from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../utils";

interface RemarkMessageProps {
	remarks: string;
	passwordRemarkStatus?: boolean;
}

const RemarkMessage: React.FC<RemarkMessageProps> = ({
	remarks,
	passwordRemarkStatus,
}) => {
	const { t } = useTranslation();

	return (
		!isNullOrUndefined(remarks) && (
			<div>
				<p
					className={`my-1 form-${
						remarks && passwordRemarkStatus === false ? false : true
					}`}
				>
					<FontAwesomeIcon
						icon={remarks && passwordRemarkStatus === false ? faTimes : faCheck}
						className="me-2"
					/>
					{t(remarks)}
				</p>
			</div>
		)
	);
};

export default RemarkMessage;
