// interface CustomWindow extends Window {
// 	ENV: {
// 		PORTAL_API_URL?: string;
// 		WEBSITE_URL?: string;
// 		OSS_URL?: string;
// 	};
// };

// declare const window: CustomWindow;

const config = {
	timezone: "Asia/Kuala_Lumpur",
	apiURL: window.ENV.PORTAL_API_URL || "http://localhost:8001/api",
	websiteURL: window.ENV.WEBSITE_URL || "http://localhost:3000",
	ossUrl:
		window.ENV.OSS_URL ||
		"https://img-staging.apexs.app/public/",
};

export default config;
