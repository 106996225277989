import React from "react";
import { Image, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import config from "../../config";
import { menu } from "../../menu-items";
import { useSelector } from "react-redux";

const MobileBottomNav: React.FC = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { userProfile } = useSelector((state: any) => state.userProfile);

	return (
		<nav
			id="mobileNav"
			className="navbar fixed-bottom navbar-light p-0"
			role="navigation"
		>
			<Nav className="subnav">
				<div className="d-flex flex-row justify-content-around w-100">
					{menu.map((menuItem, index) => {
						const isActive = pathname.startsWith(menuItem.value);
						if (menuItem.access === false) {
							menuItem.access = userProfile !== null;
						}

						return (
							menuItem.access === true && (
								<Link
									key={index}
									to={menuItem.value}
									className={`nav-link text-center ${isActive ? "active" : ""}`}
								>
									<Image
										className="mb-1"
										// src={
										// 	isActive
										// 		? config.ossUrl +
										// 			"Portal/" +
										// 			menuItem.id +
										// 			"-active.png"
										// 		: config.ossUrl + "Portal/" + menuItem.id + ".png"
										// }
										src={config.ossUrl + "Portal/" + menuItem.id + ".png"}
										width={18}
										alt={menuItem.id}
									/>
									<p>{t(menuItem.label)}</p>
								</Link>
							)
						);
					})}
				</div>
			</Nav>
		</nav>
	);
};

export default MobileBottomNav;
