import {
	UpdatePasswordParams,
	UpdatePaymentPinParams,
	UpdateUserParams,
	UpdateWalletAddresParams,
} from "../model/User";
import apiCaller from "./axios.service";

const baseUrl: string = "/user";

export const getUserProfile = async <T>(user_id: number): Promise<T> => {
	return apiCaller(`${baseUrl}/getUserProfile`, { user_id });
};

export const updateUserProfile = async <T>(
	data: UpdateUserParams
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateUserProfile`, data);
};

export const updateUserProfileImage = async <T>(
	user_id: number,
	user_profile_picture: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateUserProfileImage`, {
		user_id,
		user_profile_picture,
	});
};

export const sendEmailVerification = async <T>(
	user_id: number,
	email_verification_type: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/sendEmailVerification`, {
		user_id,
		email_verification_type,
	});
};

export const sendEmailVerificationSignUp = async <T>(
	email: string,
	email_verification_type: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/sendEmailVerificationSignUp`, {
		email,
		email_verification_type,
	});
};

export const updateUserWalletAddress = async <T>(
	data: UpdateWalletAddresParams
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateUserWalletAddress`, data);
};

export const updateUserPassword = async <T>(
	data: UpdatePasswordParams
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateUserPassword`, data);
};

export const updateUserPaymentPin = async <T>(
	data: UpdatePaymentPinParams
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateUserPaymentPin`, data);
};

export const getDepositAddress = async <T>(user_id: number): Promise<T> => {
	return apiCaller(`${baseUrl}/getDepositAddress`, { user_id });
};

export const getWithdrawAddress = async <T>(user_id: number): Promise<T> => {
	return apiCaller(`${baseUrl}/getWithdrawAddress`, { user_id });
};

export const createDepositAddress = async <T>(
	user_id: number,
	token_type: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/createDepositAddress`, { user_id, token_type });
};

export const createUpdateWithdrawAddress = async <T>(
	user_id: number,
	token_type: string,
	address: string,
	chain: string,
	payment_pin: string,
	otp: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/createUpdateWithdrawAddress`, {
		user_id,
		token_type,
		address,
		chain,
		payment_pin,
		otp,
	});
};

export const deposit = async <T>(
	user_id: number,
	token_type: string,
	chain_type: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/deposit`, { user_id, token_type, chain_type });
};

export const checkDeposit = async <T>(user_id: number): Promise<T> => {
	return apiCaller(`${baseUrl}/checkDeposit`, { user_id });
};

export const verifyUserPaymentPin = async <T>(
	user_id: number,
	payment_pin: any
): Promise<T> => {
	return apiCaller(`${baseUrl}/verifyUserPaymentPin`, {
		user_id,
		payment_pin,
	});
};
