import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import config from "../../config";
import { menu } from "../../menu-items";

const Sidebar: React.FC = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { userProfile } = useSelector((state: any) => state.userProfile);

	return (
		<div className="sidebar-custom">
			<div id="sidebar" className="sidebar-top">
				<div id="sidemenu">
					{menu.map((menuItem, index) => {
						const isActive = pathname.startsWith(menuItem.value);
						if (menuItem.access === false) {
							menuItem.access = userProfile !== null;
						}

						return (
							menuItem.access === true && (
								<Link
									key={index}
									to={menuItem.value}
									className={`nav-link ${isActive ? "active" : ""}`}
								>
									<Image
										// src={
										// 	isActive
										// 		? config.ossUrl +
										// 			"Portal/" +
										// 			menuItem.id +
										// 			"-active.png"
										// 		: config.ossUrl + "Portal/" + menuItem.id + ".png"
										// }
										src={ config.ossUrl + "Portal/" + menuItem.id + ".png"}
										width={22}
										alt={menuItem.id}
									/>
									{t(menuItem.label)}
								</Link>
							)
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
