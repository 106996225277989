import React from 'react';

interface LoaderProps {
	loading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loading }) => {
	return loading && loading === true ? <div className="spinner"></div> : null;
};

export default Loader;
