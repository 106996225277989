import apiCaller from "./axios.service";
const baseUrl = "/dashboard";

export const getDashboardDetails = (user_id: number) => {
	return apiCaller(`${baseUrl}/getDashboardDetails`, {
		user_id
	});
};

export const get7DaysBonusGraph = (
	user_id: number, filterStartDate: string, filterEndDate: string) => {
	return apiCaller(`${baseUrl}/get7DaysBonusGraph`, {
		user_id, filterStartDate, filterEndDate
	});
}

export const getBanner = () => {
	return apiCaller(`${baseUrl}/getBanner`);
};
