import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Carousel from "./CarouselCollection";
import { ResponseData } from "../model/Responses";
import { getBanner, getDashboardDetails } from "../services/dashboard.service";

const CustomCarousel: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [modalState, setModalState] = useState("");
	const [loading, setLoading] = useState(false);
	const [banner, setBanner] = useState([]);

	const isMobile = () => window.innerWidth <= 768;
	const salesRef = useRef();

	//GET DASHBOARD DETAILS
	const getDashboardDetailsRequest = async () => {
		setLoading(true);
		try {
			const result: ResponseData<any> = await getBanner();
			if (result.status) {
				setLoading(false);
				setBanner(result.data.banner);
			} else {
				setLoading(false);
				toast.error(result.message);
				return false;
			}
		} catch (error) {
			setLoading(false);
			return false;
		}
	};

	useEffect(() => {
		getDashboardDetailsRequest();
	}, []);

	return (
		<div>
			<Carousel data={banner} language={i18n.language} />
		</div>
	);
};
export default CustomCarousel;
