import config from '../config'
const ossUrl = config.ossUrl

export const languages = [
  {
    label: 'English',
    value: 'en',
    img: ossUrl + 'uk.png'
  },
  {
    label: '中文',
    value: 'zh',
    img: ossUrl + 'cn.png'
  },
  {
    label: '中文-繁體',
    value: 'tw',
    img: ossUrl + 'tw.png'
  },

]
