import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ErrorPage: React.FC = () => {
	const { t } = useTranslation();

	return (
		// <div className="d-flex justify-content-center align-items-center flex-column" style={{width:"100vw",height:"100vh"}}>
		// 	<h2>{t("Page Not Found")}</h2>
        //     <Button href="/">{t("Go Back")}</Button>
		// </div>

        <Container style={{width:"100vw",height:"100vh"}} className="d-flex justify-content-center align-items-center flex-column">
            <Row className="align-items-center text-center justify-content-center" >
                <Col xs={12}><h3>{t("Page Not Found")}</h3></Col>
                <Col xs={8}><Button href="/">{t("Go Back")}</Button></Col>
            </Row>
        </Container>
	);
};
export default ErrorPage;
