import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { RootState } from "../store";
import Header from "./header";
import Sidebar from "./menu/sidebar";
import CustomCarousel from "./customCarousel";

interface CustomRouteProps extends RouteProps {
	component: React.ComponentType<any>;
}

export const PublicRoute: React.FC<CustomRouteProps> = ({
	component: Component,
	...rest
}) => {
	const { userProfile } = useSelector((state: RootState) => state.userProfile);
	return (
		<Route
			{...rest}
			render={(props) =>
				!userProfile ? (
					<Switch>
						<Component {...props} />
					</Switch>
				) : (
					<Redirect to="/dashboard" />
				)
			}
		/>
	);
};

export const isDashboardPage = () => {
	const pathname = window.location.pathname.toLowerCase().replace(/\/+$/, "");
	return pathname === "" || pathname === "/dashboard";
};

export const PrivateRoute: React.FC<CustomRouteProps> = ({
	component: Component,
	...rest
}) => {
	const { userProfile } = useSelector((state: RootState) => state.userProfile);
	return (
		<Route
			{...rest}
			render={(props) =>
				userProfile ? (
					<>
						<Header />
						{isDashboardPage() ? <CustomCarousel /> : <div className="pt-5 mt-2"></div>}
						<div className="d-sm-flex">
							<Sidebar />
							<div className="w-100">
								<Switch>
									<section id="page-container">
										<div className="container">
											<Component {...props} />
										</div>
									</section>
								</Switch>
							</div>
						</div>
					</>
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};
