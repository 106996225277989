export const menu = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    value: '/dashboard',
    access: true,
  },
  {
    id: 'package',
    label: 'Earn',
    value: '/package',
    access: true,
  },
  {
    id: 'wallet',
    label: 'Wallet',
    value: '/wallet',
    access: false,
  },
  {
    id: 'team',
    label: 'Team',
    value: '/team',
    access: false,
  },
  {
    id: 'discover',
    label: 'Market',
    value: '/market',
    access: true,
  }
]
