import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Form, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { loginAction } from "../../actions/auth";
import logo from "../../assets/img/logo.png";
import Loader from "../../components/loader/Loader";
import RemarkMessage from "../../components/RemarkMessage";
import { isNullOrUndefined } from "../../utils";
import { languages } from "../../constants/language";
import { changeLanguage } from "../../actions/store";

interface FormInitialState {
	[key: string]: string;
}

interface FormHook {
	formData: FormInitialState;
	handleChange: (e: any) => void;
	resetFormData: () => void;
}

export function useForm(initialState: FormInitialState): FormHook {
	const [formData, setFormData] = useState<FormInitialState>(initialState);

	const handleChange = (e: { target: { id: any; value: any } }) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const resetFormData = () => {
		const emptyData = Object.fromEntries(
			Object.keys(initialState).map((key) => [key, ""])
		);
		setFormData(emptyData);
	};

	return {
		formData,
		handleChange,
		resetFormData,
	};
}

const Login: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const { formData: loginData, handleChange: handleLoginDataChange } = useForm({
		user_name: "",
		user_password: "",
	});
	const [remarks, setRemarks] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [passwordType, setPasswordType] = useState("password");
	const { userProfile } = useSelector((state: any) => state.userProfile);

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	const handleLogin = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		try {
			if (
				isNullOrUndefined(loginData.user_name) ||
				isNullOrUndefined(loginData.user_password)
			) {
				setRemarks("Please fill out all the fields");
				return false;
			}
			setLoading(true);
			setRemarks("");
			await dispatch(loginAction(loginData, t));
		} catch (error) {
			setLoading(false);
			return false;
		}
	};

	const renderField = (
		option: string,
		type: string,
		id: string,
		label: string,
		handleChange: any
	) => {
		return (
			<div className="position-relative">
				<div className="form-floating">
					<Form.Control
						type={type}
						id={id}
						placeholder={t(label)}
						onChange={handleChange}
						onKeyDown={(e) => {
							if (option === "login") {
								if (e.key === "Enter") {
									handleLogin(e);
								}
							}
						}}
					/>
					<label htmlFor={id}>{t(label)}</label>
				</div>
				{id === "user_password" && (
					<div id="password-show">
						<FontAwesomeIcon
							onClick={togglePassword}
							icon={passwordType === "text" ? faEye : faEyeSlash}
						/>
					</div>
				)}
			</div>
		);
	};

	const loginAttributes = [
		{
			id: "user_name",
			type: "text",
			label: "Username",
			required: true,
		},
		{
			id: "user_password",
			type: passwordType,
			label: "Password",
			required: true,
		},
	];

	const loginContent = (
		<>
			<form>
				{loginAttributes.map((item) =>
					renderField(
						"login",
						item.type,
						item.id,
						item.label,
						handleLoginDataChange
					)
				)}
				<RemarkMessage remarks={remarks} passwordRemarkStatus={false} />
				<a href="./forgetPassword" className="reset-password">
					{t("Forget Password?")}
				</a>
				<div className="login-footer">
					<Button disabled={loading} onClick={handleLogin}>
						<Loader loading={loading} />
						{t("Login")}
					</Button>
					<span>
						{t("Not a member yet?")}
						<a href="./signUp">{t("Sign Up")}</a>
					</span>
				</div>
			</form>
		</>
	);

	return (
		isNullOrUndefined(userProfile) && (
			<div className="auth-bg" id="authentication">
				<div className="auth-wrapper">
					<div className="text-center">
						<Image src={logo} alt="logo" className="auth-logo" width={100} />
					</div>
					<Card>
						<Card.Body>
							{/* <Card.Title>{t("Welcome back!")}</Card.Title> */}
							<div className="d-flex justify-content-between align-items-center language-btn">
								<Card.Title>{t("Welcome back!")}</Card.Title>
								<DropdownButton
									title={<i className="icon_globe-2" aria-hidden="true"></i>}
									id="dropdown-menu"
									className="ms-auto language-dropdown dropdown-width"
								>
									{languages.map((lang, index) => {
										return (
											<>
												<Dropdown.Item
													key={index}
													eventKey={index}
													onClick={() => {
														dispatch(changeLanguage(lang.value));
														localStorage.setItem("Language", lang.value);
													}}
												>
													<img src={lang.img} alt="icon" />
													{lang.label}
												</Dropdown.Item>
												{index !== languages.length - 1 && <Dropdown.Divider />}
											</>
										);
									})}
								</DropdownButton>
							</div>
							{loginContent}
						</Card.Body>
					</Card>
				</div>
			</div>
		)
	);
};

export default Login;
