import Cookies from 'js-cookie';
import {
  CLEAR_USER_PROFILE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_USER_PROFILE,
} from './types';
import { getUserProfile } from '../services/user.service';
import { toast } from 'react-toastify';
import { ResponseData } from '../model/Responses';
import { login, signOut } from '../services/public.service';
import { isNullOrUndefined } from '../utils';

const dispatchLoginFail = (t: any, dispatch: any, error: string) => {
  isNullOrUndefined(error) ? toast.error(t('Action failed. Please try again.')) : toast.error(error)
  dispatch({ type: LOGIN_FAIL });
  return Promise.reject(false);
};

export const loginAction = (data: any, t: any) => async (
  dispatch: any
) => {
  try {
    const result = await login(data);

    if (!result.status) {
      return dispatchLoginFail(dispatch, t, result.message);
    }

    toast.success(result.message);
    const userToken = result.data.connectToken;
    Cookies.set('user_token', userToken, { path: '/' });
    const userProfileResult: ResponseData<{ user_id: string }> = await getUserProfile(result.data.userId);
    if (userProfileResult.status) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          isLoggedIn: true,
          user: userProfileResult.data,
        },
      });

      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: {
          userProfile: userProfileResult.data,
        },
      });

      window.location.href = '/dashboard';
      return Promise.resolve();
    }

    return dispatchLoginFail(dispatch, t, userProfileResult.message);
  } catch (error) {
    dispatchLoginFail(dispatch, t, '');
  }
};

export const logoutAction = (data: number) => (dispatch: any) => {
  try {
    signOut(data);
    Cookies.remove('user_token');
    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAR_USER_PROFILE });
  } catch (error) {
    console.error(error);
  }
};
