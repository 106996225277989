import { lazy } from 'react';

const Login = lazy(() => import('./views/Public/login'));
const SignUp = lazy(() => import('./views/Public/signUp'));
const ForgetPassword = lazy(() => import('./views/Public/forgetPassword'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const Market = lazy(() => import('./views/Market'));
const Package = lazy(() => import('./views/Package'));
const Team = lazy(() => import('./views/Team'));
const Wallet = lazy(() => import('./views/Wallet'));
const Profile = lazy(() => import('./views/Profile'));

export const routes = [
  { path: '/', name: 'Login', component: Login, exact: true, access: true, isPublic: true },
  { path: '/login', name: 'Login', component: Login, access: true, isPublic: true },
  { path: '/signUp', name: 'Sign Up', component: SignUp, access: true, isPublic: true },
  { path: '/forgetPassword', name: 'Forget Password', component: ForgetPassword, access: true, isPublic: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, access: false, isPublic: false },
  { path: '/market', name: 'Market', component: Market, access: false, isPublic: false },
  { path: '/package', name: 'Package', component: Package, access: false, isPublic: false },
  { path: '/team', name: 'Team', component: Team, access: false, isPublic: false },
  { path: '/wallet', name: 'Wallet', component: Wallet, access: false, isPublic: false },
  { path: '/profile', name: 'Profile', component: Profile, access: false, isPublic: false }
];
