import React from 'react'
import { useSelector } from 'react-redux'
import './index.scss'
import { Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../store'

interface LoaderProps {
  loading: boolean
}

const LoadingScreen: React.FC<LoaderProps> = (props) => {
  const { t } = useTranslation()
  const { loadingText } = useSelector((state: RootState) => state.store)

  return (
    <div className={`loader-container ${props.loading ? 'w-100' : ''}`}>
      <div className='loader-wrap'>
        <span className='loader'></span>
        {loadingText ? (
          <>
            <h4>{t('Loading')}...</h4>
            <h5>{loadingText}</h5>
          </>
        ) : (
          <Image
            src='./logo.png'
            alt='logo'
            style={{ width: '200px' }}
          />
        )}
      </div>
    </div>
  )
}

export default LoadingScreen
