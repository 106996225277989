import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BannerModel } from "../model/Banner";
import { isMobile } from "../utils";

interface CarouselProps {
	data: BannerModel[];
	language: string;
}

const Carousel: React.FC<CarouselProps> = (props) => {
	const banner = props.data;
	const language = props.language;
	const settings: any = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		initialSlide: 0,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const getImagePath = (banner: any) => {
		if (isMobile()) {
			return banner?.[
				`banner_${language === "en" ? "en" : "zh"}_mobile_image_path`
			];
		}
		return banner?.[`banner_${language === "en" ? "en" : "zh"}_image_path`];
	};

	return (
		<div className="nft">
			<Slider {...settings}>
				{banner.map((banner: BannerModel) => (
					<div key={banner.banner_id}>
						<a href={banner.banner_link} target="_blank" rel="noreferrer">
							<div className="slider-border position-relative">
								<div className="w-100 h-100 position-absolute wrapper"></div>
								<img
									src={getImagePath(banner)}
									alt="Slide3"
									className="w-100 h-100"
								/>
							</div>
						</a>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default Carousel;
