import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { ActionModel } from '../model/Utilities'
import { UPDATE_USER_PROFILE, CLEAR_USER_PROFILE } from '../actions/types'

const initialState = {
  userProfile: null
}
const userProfileReducer = function (state = initialState, action: ActionModel) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: payload.userProfile
      }
    case CLEAR_USER_PROFILE:
      return {
        ...state,
        userProfile: null
      }
    default:
      return state
  }
}

export default persistReducer(
  {
    key: 'userProfile',
    storage
  },
  userProfileReducer
)
