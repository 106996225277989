import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	AUTH_FAIL,
	AUTH_SUCCESS,
} from "../actions/types";
import { ActionModel } from "../model/Utilities";
import { UserModel } from "../model/User";

interface AuthState {
	user: UserModel | null;
	isLoggedIn: boolean;
}

const initialState: AuthState = {
	user: null,
	isLoggedIn: false,
};

export default function (state = initialState, action: ActionModel) {
	const { type, payload } = action;
	switch (type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				user: payload.user,
			};
		case LOGIN_FAIL:
			return {
				...state,
				isLoggedIn: false,
				user: null,
			};
		case AUTH_FAIL:
			return {
				...state,
				isLoggedIn: false,
				user: null,
			};
		case AUTH_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				user: payload.user,
			};
		case LOGOUT:
			return {
				...state,
				isLoggedIn: false,
				user: null,
			};
		default:
			return state;
	}
}
