import {
    LoginParams,
    LoginResult,
    ResetPasswordParams
} from "../model/User";
import apiCaller from "./axios.service";

const publicUrl: string = "/public";
const baseUrl: string = "/identity";

export const login = async (data: LoginParams): Promise<LoginResult> => {
    return apiCaller(`${baseUrl}/signIn`, data);
};

export const signUp = async <T>(data: any): Promise<T> => {
    return apiCaller(`${baseUrl}/signUp`, data);
};

export const signOut = async <T>(user_id: number): Promise<T> => {
    return apiCaller(`${baseUrl}/signOut`,
        { user_id }
    );
};

export const forgetPassword = async <T>(
    data: ResetPasswordParams
): Promise<T> => {
    return apiCaller(`${baseUrl}/forgetPassword`, data);
};

export const getCountryList = async <T>(
): Promise<T> => {
    return apiCaller(`${publicUrl}/getCountryList`);
};

export const getDefaultUpline = async <T>(
): Promise<T> => {
    return apiCaller(`${publicUrl}/getDefaultUpline`);
};

export const getAllUser = async <T>(
): Promise<T> => {
    return apiCaller(`${publicUrl}/getAllUser`);
};

export const getDepositAddress = async <T>(
): Promise<T> => {
    return apiCaller(`${publicUrl}/getDepositAddress`);
};

export const getTop10Token = async <T>(
): Promise<T> => {
    return apiCaller(`${publicUrl}/getTop10Token`);
};

